import { ReactElement, useEffect, useState } from "react";
import { Case, makeName } from "../../../utils/utils";
import { WizardStep } from "../../../components/StepsWizard";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ServiceContactForm from "../../serviceContact/ServiceContactForm";
import RoundedBox from "../../../components/layout/RoundedBox";
import { FormButtonLight } from "../../../components/forms/FormButton";
import { useAuth } from "../../../hooks/auth";
import useServiceContacts from "../../../hooks/useServiceContacts";

const ServiceContactsStep = ({ step, fcase, saveFcase, fetchFcase, onCourtChange, onCategoryChange, fetchPaymentAccounts, onCaseTypeChange, setShowNextButton, ...props }: { step: WizardStep, fcase: Case, saveFcase: Function, fetchFcase: Function, onCourtChange: Function, onCategoryChange: Function, fetchAttorneys: Function, fetchPaymentAccounts: Function, onCaseTypeChange: Function, setShowNextButton: Function }): ReactElement => {

  const [showAddNewServiceContact, setShowAddNewServiceContact] = useState(false);
  const { axios } = useAuth({ middleware: 'auth' });//#TODO: eliminar de aca meter en un hook lo que la use removeServiceContact

  const { removeServiceContactFromCase } = useServiceContacts()

  useEffect(() => {
    if(!fcase) {
      return;
    }
    if(fcase.service_contacts.length == 0) {
      enableShowServiceContactForm();
    }
  }, [fcase])

  const onSubmitServiceContacts = () => {
    setShowAddNewServiceContact(false);
    fetchFcase();
    // onFCaseChange && onFCaseChange();
  };

  const removeServiceContact = async (serviceContact: any) => {
    await removeServiceContactFromCase(fcase, serviceContact)
    fetchFcase()
  }

  const makeServiceContactName = (value: any, service_contact: any) => {
    return service_contact.user ? makeName(service_contact.user) : makeName(service_contact);
  }

  const makeEmail = (value: any, service_contact: any) => {
    return service_contact.user ? service_contact.user.email : service_contact.email;
  }

  const makeFirmName = (value: any, service_contact: any) => {
    return service_contact.firm ? service_contact.firm.name : service_contact.firm_name;
  }

  const enableShowServiceContactForm = () => {
    setShowNextButton(false)
    setShowAddNewServiceContact(true)
    step.props.showingNewFilingForm = true
    step.props.disableShowServiceContactForm = disableShowServiceContactForm
  }

  const disableShowServiceContactForm = () => {
    setShowNextButton(true)
    setShowAddNewServiceContact(false)
    delete step.props.showingNewFilingForm
    delete step.props.disableShowFilingForm
  }

  const actions = (params: GridRenderCellParams) => <div className="actions">
    <RemoveCircleIcon
      onClick={() => removeServiceContact(params.row)}
      className="cursor-pointer hover:opacity-75"
    />
  </div>

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Name', flex: 250, valueGetter: makeServiceContactName },
    { field: 'email', headerName: 'Email', flex: 200, valueGetter: makeEmail },
    { field: 'firm_name', headerName: 'Firm', flex: 200, valueGetter: makeFirmName },
    { field: 'id', headerName: 'Action', flex: 50, renderCell: actions, align: 'right', headerAlign: 'right' },
  ];

  // -------------------------------------------------

  if(!fcase) {
    return <></>
  }

  return <RoundedBox className="bg-white mb-4">
    {
      !showAddNewServiceContact && <>
        <div className="mb-4">
          Case Service Contacts
        </div>
        {
          step.current && <DataGrid
            autoHeight // not working
            rows={fcase.service_contacts}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            className="grid-mfr mb-4"
            rowSelection={false}
          />
        }
        <div className="text-center">
          <FormButtonLight onClick={enableShowServiceContactForm} className="m-auto w-1/4 rounded-full">
            Add Service Contact
          </FormButtonLight>
        </div>
      </>
    }
    {
      showAddNewServiceContact && <ServiceContactForm onSubmitSuccess={onSubmitServiceContacts} fcase={fcase} />
    }
  </RoundedBox>
};

export default ServiceContactsStep;
