import { ReactElement } from "react";
import PrivateLayout from "../components/layout/PrivateLayout";
import RoundedBox from "../components/layout/RoundedBox";

const MyEServicesScreen = ():ReactElement => {
  return <PrivateLayout>
    <RoundedBox color="normal">
        My E-Filings
    </RoundedBox>
  </PrivateLayout>;
};

export default MyEServicesScreen;
