import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../components/layout/PrivateLayout";
import RoundedBox from "../components/layout/RoundedBox";
import { selectAuth, useAuth } from "../hooks/auth";
import { Link } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { DateTime } from "luxon";
import { User } from "../repositories/Models";
import { useSelector } from "react-redux";
import Notifications from "../components/Notifications";

const HomeScreen = (): ReactElement => {
  const { getUserData } = useAuth({ middleware: "auth" });

  // const [user, setUser]: [User | undefined, Function] = useState();
  const user: User = useSelector(selectAuth);
  // const user:User|undefined = getUserData();
  const [fillingAccepted, setFillingAccepted]: [number | undefined, Function] =
    useState();
  const [fillingSubmitted, setFillingSubmitted]: [
    number | undefined,
    Function,
  ] = useState();
  const [fillingRejected, setFillingRejected]: [number | undefined, Function] =
    useState();
  const [fillingFrom, setFillingFrom]: [string | undefined, Function] =
    useState();

  useEffect(() => {
    getUserData();
    //#TODO: get real data
    setFillingAccepted("08");
    setFillingSubmitted("05");
    setFillingRejected("03");
    setFillingFrom(
      DateTime.now().minus({ days: 15 }).toFormat("cccc, d'th at' hh:mm'hs'"),
    );
  }, []);

  return (
    <>
      {user && (
        <PrivateLayout>
          <RoundedBox
            className="bg-background-overlay-blue bg-home-title-man bg-no-repeat bg-contain mb-4 relative"
            style={{ backgroundPosition: "top 0 right 2.5em" }}
          >
            <div className="p-6">
              <h2 className="text-base-blue text-2xl mb-2">
                Welcome back {user.first_name},
              </h2>
              <p>Have a nice day at work!</p>
            </div>
            <Notifications />
          </RoundedBox>
          <div className="flex gap-4 mb-4">
            <RoundedBox className="bg-white w-3/5 text-center">
              <h3 className="text-2xl mb-6">FILE TO COURT</h3>
              <p className="mb-6">
                File documents to court. You may also simultaneously
                <br />
                send documents to opposing counsel.
              </p>
              <p className="text-base-blue font-bold mb-6">
                No time to File? We can do it for you.
              </p>
              <Link
                to="/file-to-court"
                className="px-16 py-3 text-white bg-base-blue rounded hover:bg-hovered-light-blue active:bg-active-light-blue inline-block"
              >
                <FileUploadIcon className="mr-2" />
                File Documents
              </Link>
            </RoundedBox>
            <RoundedBox className="bg-white w-2/5 text-center">
              <h3 className="text-2xl mb-6">SERVE TO ATTORNEY</h3>
              <p className="mb-16">
                Send docs to opposing counsel only.
                <br />
                Not filed with court.
              </p>
              <Link
                to="/serve-to-attorney"
                className="px-16 py-3 text-base-blue bg-background-overlay-blue rounded hover:bg-hovered-light-blue hover:text-white active:bg-active-light-blue inline-block"
              >
                <FileUploadIcon className="mr-2" />
                Send Documents
              </Link>
            </RoundedBox>
          </div>
          <RoundedBox className="bg-background-overlay-blue flex gap-4">
            <div className="w-1/4 flex-initial pl-6 py-10 flex">
              <div className="flex-1 flex items-center">
                <div>
                  <p className="text-xl mb-6 font-semibold">
                    Updates on
                    <br />
                    your Filings!
                  </p>
                  <p>
                    Your Filings statistics since
                    <br />
                    {fillingFrom}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-3/4 flex-grow flex gap-4">
              <RoundedBox className="bg-background-blue flex-1 text-center">
                <p className="font-bold">New Filings Accepted</p>
                <div className="text-white rounded-full text-4xl font-bold size-16 flex items-center text-center my-8 bg-green-600 opacity-50 m-auto">
                  <div className="flex-1 text-center">{fillingAccepted}</div>
                </div>
                <Link
                  to={"#"}
                  className="text-center py-2 max-w-80 block rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white m-auto"
                >
                  View All
                </Link>
              </RoundedBox>
              <RoundedBox className="bg-background-blue flex-1 text-center">
                <p className="font-bold">New Filings Submitted</p>
                <div className="text-white rounded-full text-4xl font-bold size-16 flex items-center text-center my-8 bg-yellow-400 opacity-50 m-auto">
                  <div className="flex-1 text-center">{fillingSubmitted}</div>
                </div>
                <Link
                  to={"#"}
                  className="text-center py-2 max-w-80 block rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white m-auto"
                >
                  View All
                </Link>
              </RoundedBox>
              <RoundedBox className="bg-background-blue flex-1 text-center">
                <p className="font-bold">New Filings Rejected</p>
                <div className="text-white rounded-full text-4xl font-bold size-16 flex items-center text-center my-8 bg-red-600 opacity-50 m-auto">
                  <div className="flex-1 text-center">{fillingRejected}</div>
                </div>
                <Link
                  to={"#"}
                  className="text-center py-2 max-w-80 block rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white m-auto"
                >
                  View All
                </Link>
              </RoundedBox>
            </div>
          </RoundedBox>
          <p className="my-4 text-base">Searching For a Report?</p>
          <RoundedBox className="bg-white flex rounded-full mb-4">
            <div className="flex-1 border-r border-background-overlay-blue px-4">
              <label className="text-black mb-1 block">Document Type</label>
              <input placeholder="My Filing or Firms Filing" />
            </div>
            <div className="flex-1 border-r border-background-overlay-blue px-4">
              <label className="text-black mb-1 block">
                Jurisdiction or Location *
              </label>
              <input placeholder="Select Location" />
            </div>
            <div className="flex-1 border-r border-background-overlay-blue px-4">
              <label className="text-black mb-1 block">Envelope Number</label>
              <input placeholder="Insert Envelope Number" />
            </div>
            <div className="flex-1 pl-4 flex">
              <div className="flex-1">
                <label className="text-black mb-1 block">Date</label>
                <input placeholder="Select Date Range" />
              </div>
              <button className="flex-initial rounded-full bg-base-blue text-white p-2 hover:bg-hovered-light-blue">
                <SearchIcon fontSize="large" />
              </button>
            </div>
          </RoundedBox>
          <p className="block text-right text-sm">
            <a
              href="#"
              className="hover:text-base-blue font-bold text-base-blue"
            >
              More filters&nbsp;
              <TuneIcon />
            </a>
          </p>
        </PrivateLayout>
      )}
    </>
  );
};

export default HomeScreen;
