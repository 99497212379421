import { ReactElement } from "react";
import { Box, Switch } from "@mui/material";
import RoundedBox from "../../../components/layout/RoundedBox";

const Notifications = (): ReactElement => {
  return (
    <RoundedBox className="bg-white h-full">
      <Box className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-[#5261A0]">Notifications:</h2>
      </Box>
      <Box className="flex items-center">
        <p className="font-bold text-sm">Desktop Push Notifications:</p>
        <Switch color="primary" />
      </Box>
    </RoundedBox>
  );
};

export default Notifications;
