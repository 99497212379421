import { ReactElement, useEffect, useMemo, useState } from "react";
import DialogCloseable from "../../components/dialogs/DialogCloseable";
import * as yup from 'yup';
import { useFormik } from "formik";
import FormInput from "../../components/forms/FormInput";
import FormSelect from "../../components/forms/FormSelect";
import FormSelectMultiple from "../../components/forms/FormSelectMultiple";
import FormLabel from "../../components/forms/FormLabel";
import { useAuth } from "../../hooks/auth";
import PrivateLayout from "../../components/layout/PrivateLayout";
import { FileCaseRow } from "./FileToCourt";
import { selectCases, setCases } from "../../components/assets/CaseSearch";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RoundedBox from "../../components/layout/RoundedBox";
import { DataGrid, GridColDef, GridRenderCellParams, GridVisibilityOffIcon } from "@mui/x-data-grid";
import { FormButtonLight } from "../../components/forms/FormButton";
import { ButtonAction } from "../../components/table/ButtonAction";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import useFilingCases from "../../hooks/useFilingCases";
import { useMessageBoard } from "../../components/assets/MessageBoard";

export const SearchCaseDialog = ({ open, onClose, ...props }: any): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });

  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();

  const search_by = ['Case', 'Party'];
  const business_types = ['Personal', 'Business'];

  useEffect(() => {
    if (!open || locations.length > 0) {
      return;
    }
    fetchLocations()
  }, [open]);

  const fetchLocations = async () => {
    setLocations((await axios.get(`/locations/all`, { params: { type: 'initial' } })).data);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location_id: 224,
      search_by: 'Case',
      case_number: '5Y9D001CM',
      business_type: 'Personal',
      first_name: '',
      middle_name: '',
      last_name: '',
      business_name: '',
    },
    validationSchema: () => {
      const rules: any = {
        location_id: yup.string().required('Please select a location'),
      }
      if (formik.values.search_by == 'Case') {
        rules.case_number = yup.string().required('Please enter a case number');
      } else {
        if (formik.values.business_type == 'Personal') {
          rules.first_name = yup.string().required('Please enter a first name');
          rules.last_name = yup.string().required('Please enter a last name');
        } else {
          rules.business_name = yup.string().required('Please enter a business name');
        }
      }
      return yup.object().shape(rules);
    },
    onSubmit: async (params: any) => {
      try {
        setCases([]);
        const cases = (await axios.get('/filing-cases/search', { params })).data;
        setCases(cases);
        navigate('/search-case');

      } catch (e) {
        console.error(e)
      }
    }
  });

  return <DialogCloseable
    width="600px"
    title="File Into An Existing Case"
    subtitle="Search Case Details"
    open={open}
    onClose={onClose}
  >
    <form onSubmit={formik.handleSubmit} className="text-sm">
      <div className="flex flex-col gap-4">
        <div className="flex-1">
          <FormLabel>Location</FormLabel>
          <FormSelect
            formik={formik}
            options={locations}
            optionValue="id"
            optionLabel="name"
            name="location_id"
            searchable
          />
        </div>
        <div className="flex-1">
          <FormLabel>Search By</FormLabel>
          <FormSelectMultiple
            formik={formik}
            options={search_by}
            name="search_by"
            inline={true}
            type='radio'
          />
        </div>
        {
          formik.values.search_by == 'Case' && <div className="flex-1">
            <FormLabel>Case Number</FormLabel>
            <FormInput formik={formik} name="case_number" />
          </div>
        }
        {
          formik.values.search_by == 'Party' && <>
            <div className="flex-1">
              <FormLabel>Party Type</FormLabel>
              <FormSelectMultiple
                formik={formik}
                options={business_types}
                name="business_type"
                inline={true}
                type='radio'
              />
            </div>
            {
              formik.values.business_type == 'Personal' && <>
                <div className="flex-1">
                  <FormLabel>First Name</FormLabel>
                  <FormInput formik={formik} name="first_name" />
                </div>
                <div className="flex-1">
                  <FormLabel>Middle Name</FormLabel>
                  <FormInput formik={formik} name="middle_name" />
                </div>
                <div className="flex-1">
                  <FormLabel>Last Name</FormLabel>
                  <FormInput formik={formik} name="last_name" />
                </div>
              </>
            }
            {
              formik.values.business_type == 'Business' && <div className="flex-1">
                <FormLabel>Business Name</FormLabel>
                <FormInput formik={formik} name="business_name" />
              </div>
            }
          </>
        }
        <div className="flex-1 text-center">
          <FormButtonLight className="mt-6 w-1/3">
            Search
          </FormButtonLight>
        </div>
      </div>
    </form>
  </DialogCloseable>
};

const SearchCaseScreen = ({ open, onClose, ...props }: any): ReactElement => {

  const cases = useSelector(selectCases);
  const navigate = useNavigate();
  const { fetchExistingFilingCaseById } = useFilingCases();
  const { addMessageError } = useMessageBoard();

  const fileIntoThisFcase = async (id: string, location_id: number) => {
    try {
      const fcase = await fetchExistingFilingCaseById(id, location_id, true);
      console.log(fcase)
      navigate(`/file-into-existing-case/${fcase?.id}`);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  const actions = (params: GridRenderCellParams) => <div className="actions">
    <Link to={`/case-details/${params.row.tracking_id}/${params.row.location_id}`}>
      <RemoveRedEyeOutlinedIcon />
    </Link>
    <ButtonAction onClick={() => fileIntoThisFcase(params.row.tracking_id, params.row.location_id)}>
      File
    </ButtonAction>
  </div>

  const columns: GridColDef[] = [
    { field: 'location', headerName: 'Location', flex: 1.5 },
    { field: 'title', headerName: 'Title', flex: 3 },
    { field: 'docket_id', headerName: 'Case Number', flex: 1 },
    { field: 'case_type', headerName: 'Case Type', flex: 1 },
    { field: 'actions', headerName: 'Actions', flex: 1, renderCell: actions, align: 'right', headerAlign: 'right' },
  ];

  return <PrivateLayout>
    <FileCaseRow />
    <RoundedBox className="bg-white mt-4">
      <DataGrid
        className="grid-mfr"
        rows={cases}
        columns={columns}
        rowSelection={false}
        // hideFooterPagination={true}
        getRowId={(row: any) => row?.tracking_id}
      />
    </RoundedBox>
  </PrivateLayout>
}

export default SearchCaseScreen;
