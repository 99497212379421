import { ReactElement, useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import GavelIcon from "@mui/icons-material/Gavel";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import GridViewIcon from "@mui/icons-material/GridView";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { Location, NavLink, useLocation } from "react-router-dom";
import { MenuItem } from "@mui/material";
import UserMenu from "../UserMenuButton";
import { useAuth } from "../../hooks/auth";

interface MenuItem {
  icon: ReactElement;
  name: string;
  url: string;
  selected?: boolean;
  items?: SubMenuItem[];
  matches?: string[];
  classNames?: {
    link: string;
  };
}

interface SubMenuItem {
  name: string;
  url: string;
  selected?: boolean;
  matches?: string[];
  classNames?: {
    link: string;
    li: string;
  };
}

const SideBar = (): ReactElement => {
  const location: Location = useLocation();
  const [menu, setMenu]: [(MenuItem | string)[], Function] = useState([]);

  const {hasRole} = useAuth({middleware: 'auth'})

  const baseMenu: (MenuItem | string)[] = [];
  baseMenu.push("MAIN MENU");
  baseMenu.push({
    icon: <HomeIcon />,
    name: "Home",
    url: "/",
    items: [
      {
        name: "File To Court",
        url: "/file-to-court",
        matches: ["/file-case"],
      },
      {
        name: "Serve To Attorney",
        url: "/serve-to-attorney",
      },
    ],
  });
  baseMenu.push({
    icon: <GavelIcon />,
    name: "My Filings",
    url: "/my-filings",
  });
  baseMenu.push({
    icon: <PeopleAltIcon />,
    name: "My E-Services",
    url: "/my-e-services",
  });
  baseMenu.push({
    icon: <BusinessCenterIcon />,
    name: "My Cases",
    url: "/my-cases",
  });
  baseMenu.push({
    icon: <InventoryIcon />,
    name: "My Reports",
    url: "/my-reports",
  });
  baseMenu.push({
    icon: <QueryStatsIcon />,
    name: "Statistics",
    url: "/statistics",
  });

  if (hasRole('admin')) {
    baseMenu.push("ADMINISTRATION");
    baseMenu.push({
      icon: <GridViewIcon />,
      name: "Admin Dashboard",
      url: "/admin-dashboard/user-management",
      items: [
        {
          name: "User Management",
          url: "/admin-dashboard/user-management",
        },
        {
          name: "Payment Accounts",
          url: "/admin-dashboard/payment-accounts",
        },
      ],
    });
    baseMenu.push({
      icon: <AdminPanelSettingsIcon />,
      name: "Admin Privilege",
      url: "/admin-privilege",
    });
  }

  baseMenu.push("hr");
  baseMenu.push({
    icon: <SupportAgentIcon />,
    name: "Contact Us",
    url: "/contact-us",
  });

  useEffect(() => {
    const menu = baseMenu.map((menuItem) => {
      if (typeof menuItem == "string") {
        return menuItem;
      }
      if (menuItem.items) {
        menuItem.items = menuItem.items.map((subMenuItem: SubMenuItem) => {
          let selected = subMenuItem.url == location.pathname;
          selected =
            selected ||
            (!!subMenuItem.matches &&
              subMenuItem.matches.includes(location.pathname));
          const classNames = {
            link: classNamesLinkSubMenuItem(selected),
            li: classNamesLiSubMenuItem(selected),
          };
          return { ...subMenuItem, selected, classNames };
        });
      }
      let selected = menuItem.url == location.pathname;
      selected =
        selected ||
        (!!menuItem.items &&
          menuItem.items.reduce(
            (selected, subMenuItem) => subMenuItem.selected || selected,
            false,
          ));
      selected =
        selected ||
        (!!menuItem.matches && menuItem.matches.includes(location.pathname));
      const classNames = {
        link: classNamesLinkMenuItem(selected),
      };
      return { selected, ...menuItem, classNames };
    });
    setMenu(menu);
  }, [location.pathname]);

  const classNamesLinkMenuItem = (selected?: boolean): string => {
    let classes = `rounded-lg py-3 px-4 cursor-pointer flex gap-6 menu-item hover:bg-background-overlay-blue`;
    classes += !selected ? "" : " bg-active-light-blue text-black font-bold";
    return classes;
  };

  const classNamesLiSubMenuItem = (selected?: boolean): string => {
    let classes = `pl-5 `;
    classes += !selected
      ? "border-l-1 border-base-gray"
      : "border-l-3 border-base-blue";
    return classes;
  };

  const classNamesLinkSubMenuItem = (selected?: boolean): string => {
    let classes = `rounded-lg py-2 px-4 cursor-pointer flex gap-6 menu-item hover:bg-background-overlay-blue relative`;
    classes += !selected ? "" : " text-base-blue font-bold";
    return classes;
  };

  return (
    <div className="text-md fixed top-4 bottom-4 left-4 w-64 flex flex-col gap-4">
      <div className="flex-initial">
        <img
          src="/images/logo-header.svg"
          alt="Logo"
          className="object-contain w-full"
        />
      </div>
      <div className="flex-1 sidebar overflow-y-auto">
        <ul>
          {menu.map((menuItem, menuIndex) => (
            <li key={`sidebar-item-${menuIndex}`} className="mb-2">
              {typeof menuItem != "string" && (
                <>
                  <NavLink
                    to={menuItem.url}
                    className={menuItem.classNames?.link}
                  >
                    <div
                      className="flex-initial"
                      style={{ position: "relative", bottom: "2px" }}
                    >
                      {menuItem.icon ? menuItem.icon : ""}
                    </div>
                    <div className="flex-1">{menuItem.name}</div>
                  </NavLink>
                  {menuItem.selected && menuItem.items && (
                    <ul className="ml-7 my-3">
                      {menuItem.items.map((subMenuItem, subMenuItemIndex) => (
                        <li
                          key={`sidebar-item-${menuIndex}-subitem-${subMenuItemIndex}`}
                          className={subMenuItem.classNames?.li}
                        >
                          <NavLink
                            to={subMenuItem.url}
                            className={subMenuItem.classNames?.link}
                            style={{
                              right: subMenuItem.selected ? "2px" : 0,
                            }}
                          >
                            {subMenuItem.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
              {typeof menuItem == "string" && (
                <>
                  {menuItem != "hr" && (
                    <div className="my-4 text-xs">{menuItem}</div>
                  )}
                  {menuItem == "hr" && <hr className="my-3" />}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex-initial">
        <UserMenu />
      </div>
    </div>
  );
};

export default SideBar;
