import { ReactElement, useEffect, useRef, useState } from "react";
import { ButtonALight, FormButtonLight } from "../../../components/forms/FormButton";
import FormLabel from "../../../components/forms/FormLabel";
import FormInput from "../../../components/forms/FormInput";
import FormSelect from "../../../components/forms/FormSelect";
import { Case, CaseCategory, checkChanged, EMPTY_CASE, makeName } from "../../../utils/utils";
import { selectAuth, useAuth } from "../../../hooks/auth";
import DialogCloseable from "../../../components/dialogs/DialogCloseable";
import AttorneyForm from "../../attorneys/AttorneyForm";
import PaymentAccountForm from "../../payment-accounts/PaymentAccountsForm";
import FormSelectMultiple from "../../../components/forms/FormSelectMultiple";
import { useFormik } from "formik";
import * as yup from 'yup';
import { WizardStep } from "../../../components/StepsWizard";
import { selectCaseTypes, selectCategories, selectCourts, selectDamageAmounts, selectFilerTypes, selectFilingCodes, selectPartyTypes, selectPaymentAccounts, selectProcedureRemedies } from "../../../components/assets/CaseWizard";
import { useSelector } from "react-redux";
import usePaymentAccounts from "../../../hooks/usePaymentAccount";
import useConfigs from "../../../hooks/useConfigs";
import useAttorneys from "../../../hooks/useAttorneys";
import { Profile, User } from "../../../repositories/Models";
import useUsers from "../../../hooks/useUsers";

const GeneralInfoStep = ({ step, fcase, saveFcase, fetchFcase, onCourtChange, onCategoryChange, fetchPaymentAccounts, onCaseTypeChange, ...props }: { step: WizardStep, fcase: Case, saveFcase: Function, fetchFcase: Function, onCourtChange: Function, onCategoryChange: Function, fetchAttorneys: Function, fetchPaymentAccounts: Function, onCaseTypeChange: Function }): ReactElement => {

  const user: Profile = useSelector(selectAuth);

  const {isVisible, isRequired} = useConfigs();

  const courts = useSelector(selectCourts)
  const caseTypes = useSelector(selectCaseTypes)
  const {attorneys, fetchAttorneys} = useAttorneys()
  const {syncUsers} = useUsers()
  const {paymentAccounts, syncPaymentAccounts} = usePaymentAccounts();
  const categories = useSelector(selectCategories)
  const filerTypes = useSelector(selectFilerTypes)
  const procedureRemedies = useSelector(selectProcedureRemedies)
  const damageAmounts = useSelector(selectDamageAmounts)
  const partyTypes = useSelector(selectPartyTypes)
  const filingCodes = useSelector(selectFilingCodes)

  const [showAddNewAttorney, setShowAddNewAttorney]: [boolean, Function] = useState(false)
  const [showAddNewPaymentAccount, setShowAddNewPaymentAccount]: [boolean, Function] = useState(false)

  const [currentCategory, setCurrentCategory]: [CaseCategory | undefined, Function] = useState()

  const {hasRole} = useAuth({middleware: 'auth'})
  
  useEffect(() => {
    if(!fcase) {
      return
    }
    formik.setValues(fcase)
  }, [fcase]);

  // -------------------------------------------------

  const changeCourt = (court: any) => {
    formik.setFieldValue('case_category_id', undefined);
    onCourtChange(court);
    changeCategory(undefined, undefined)
  };

  const changeCategory = (category: any, option: any) => {
    formik.setFieldValue('case_type_id', undefined);
    setCurrentCategory(category);
    onCategoryChange(category)
  };

  // -------------------------------------------------

  const onSubmitAttorneys = () => {
    fetchAttorneys()
    setShowAddNewAttorney(false)
  }

  const onSubmitPaymentAccounts = () => {
    // fetchPaymentAccounts()
    setShowAddNewPaymentAccount(false)
  }

  // -------------------------------------------------

  const formik = step.props.formik = useFormik({
    initialValues: EMPTY_CASE,
    validationSchema: () => {
      const validations: any = {
        location_id: yup.string().required("Please select a court"),
        case_type_id: yup.string().required("Please select a case type"),
        title: yup.string().required("Please enter a case title"),
        case_category_id: yup.string().required("Please select a category"),
        attorney_id: yup.string().required("Please select a attorney"),
        payment_account_id: yup.string().required("Please select a payment account")
      };
      if(isRequired('FilingFilerType')) {
        validations.filer_type_id = yup.string().required("Please select a filer type");
      }
      if ([currentCategory?.damage_amount_initial, currentCategory?.damage_amount_subsequent].includes('Required')) {
        validations.damage_amount_id = yup.string().required("Please select a damage sought")
      }
      if ([currentCategory?.procedure_remedy_subsequent, currentCategory?.procedure_remedy_subsequent].includes('Required')) {
        validations.procedure_remedy_id = yup.array().min(1, 'Please select a option')
      }
      return yup.object().shape(validations);
    },
    onSubmit: async (values, { setSubmitting }) => {
      if(!checkChanged(fcase, values)) {
        return;
      }
      return await saveFcase(values)
    }
  });

  // -------------------------------------------------

  const syncAttorneys = async () => {
    await syncUsers()
    await fetchAttorneys()
  }

  return <>
    <label className="block mb-4">
      Enter case information.
    </label>
    <form onSubmit={formik.handleSubmit}>
      <div className="flex gap-4">
        <div className="flex-1">
          <div className="mb-6">
            <FormLabel mandatory>
              Case title
            </FormLabel>
            <FormInput
              formik={formik}
              name="title"
            />
          </div>
          <div className="mb-6">
            <FormLabel mandatory>
              Choose Case Category
            </FormLabel>
            <FormSelect
              name="case_category_id"
              formik={formik}
              options={categories}
              optionValue="id"
              optionLabel="name"
              empty="Select Case Category"
              onChange={changeCategory}
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-6">
            <FormLabel mandatory>
              Choose the Court you wish to file in
            </FormLabel>
            <FormSelect
              name="location_id"
              formik={formik}
              options={courts}
              optionValue="id"
              optionLabel="name"
              empty="Select Court"
              onChange={changeCourt}
              searchable
            />
          </div>
          <div className="mb-6">
            <FormLabel mandatory>
              Choose Case Type
            </FormLabel>
            <FormSelect
              name="case_type_id"
              formik={formik}
              options={caseTypes}
              optionValue="id"
              optionLabel="name"
              empty="Select Case Type"
              onChange={onCaseTypeChange}
            />
          </div>
        </div>
      </div>
      {
        isVisible('FilingFilerType') && <div className="flex gap-4 mb-6">
          <div className="flex-1">
            <FormLabel mandatory={isRequired('FilingFilerType')}>
              Filer Type
            </FormLabel>
            <FormSelect
              name="filer_type_id"
              formik={formik}
              options={filerTypes}
              optionValue="id"
              optionLabel="name"
              empty="Select Filer Type"
            />
          </div>
          <div className="flex-1"></div>
        </div>

      }
      <FormLabel mandatory>
        Select Attorney
      </FormLabel>
      <div className="flex gap-4 mb-6">
        <div className="flex-1">
          <FormSelect
            name="attorney_id"
            formik={formik}
            options={attorneys}
            optionValue="id"
            optionLabel={makeName}
            empty="Select Attorney"
          />
        </div>
        <div className="flex-1">
          {
            hasRole('admin') && <span className="flex gap-2">
              <ButtonALight onClick={() => setShowAddNewAttorney(true)}>
                Add New Attorney
              </ButtonALight>
              {
                false && 'no funciona el backend todavía' && <ButtonALight onClick={syncAttorneys}>
                  Sync
                </ButtonALight>
              }
            </span>
          }
        </div>
      </div>
      <FormLabel mandatory>
        Payment Account
      </FormLabel>
      <div className="flex gap-4 mb-6">
        <div className="flex-1">
          <FormSelect
            name="payment_account_id"
            formik={formik}
            options={paymentAccounts}
            optionValue="id"
            optionLabel="name"
            empty="Select Payment Account"
          />
        </div>
        <div className="flex-1">
          {
            hasRole('admin') && <span className="flex gap-2">
              <ButtonALight onClick={() => setShowAddNewPaymentAccount(true)}>
                Add New Payment Account
              </ButtonALight>
              <ButtonALight onClick={syncPaymentAccounts}>
                Sync
              </ButtonALight>
            </span>
          }
        </div>
      </div>
      {
        procedureRemedies && procedureRemedies.length > 0 && <div className="flex gap-4 mb-6">
          <div className="flex-1">
            <FormLabel mandatory={currentCategory?.procedure_remedy_initial == 'Required' || currentCategory?.procedure_remedy_subsequent == 'Required'}>
              Procedures/Remedies
            </FormLabel>
            <FormSelectMultiple
              name="filing_case_procedure_remedies"
              formik={formik}
              options={procedureRemedies}
              optionValue="id"
              optionLabel="name"
              styles={{maxHeight: '200px'}}
            />
          </div>
          <div className="flex-1"></div>
        </div>
      }
      {
        damageAmounts && damageAmounts.length > 0 && <div className="flex gap-4 mb-6">
          <div className="flex-1">
            <FormLabel mandatory={currentCategory?.damage_amount_initial == 'Required' || currentCategory?.damage_amount_subsequent == 'Required'}>
              Damages Sought
            </FormLabel>
            <FormSelect
              name="damage_amount_id"
              formik={formik}
              options={damageAmounts}
              optionValue="id"
              optionLabel="name"
              empty="Select a Damage Sought"
            />
          </div>
          <div className="flex-1"></div>
        </div>
      }
      {
        <DialogCloseable
          open={showAddNewAttorney}
          onClose={() => setShowAddNewAttorney(false)}
          title="Add Attorney"
        >
          <AttorneyForm onSubmitSuccess={() => onSubmitAttorneys()} />
        </DialogCloseable>
      }
      {
        <DialogCloseable
          open={showAddNewPaymentAccount}
          onClose={() => setShowAddNewPaymentAccount(false)}
          title="Add Payment Account"
        >
          <PaymentAccountForm onSubmitSuccess={() => onSubmitPaymentAccounts()} />
        </DialogCloseable>
      }
    </form>
  </>
};

export default GeneralInfoStep;
