import { TextField, TextFieldProps } from "@mui/material";

const SmallTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      fullWidth
      size="small"
      variant="standard" // Use 'standard' variant for underline/bottom border only
      InputProps={{
        sx: {
          fontSize: "14px", // Adjust font size
          height: "20px",
        },
        disableUnderline: false, // Keeps the bottom border
      }}
    />
  );
};

export default SmallTextField;
