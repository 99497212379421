import { useState } from "react";
import { Box, Grid, Radio, FormControlLabel, RadioGroup } from "@mui/material";
import { Edit } from "@mui/icons-material";
import RoundedBox from "../../../components/layout/RoundedBox";

const EmailPreferences = () => {
  const [isEditing, setIsEditing] = useState(false);

  // State for each group of email preferences
  const [efileEmails, setEfileEmails] = useState({
    "Filing Accepted": "Yes",
    "Filing Rejected": "Yes",
    "Filing Submitted": "Yes",
    "Filing Submission Failed": "Yes",
    "Filing has been returned for correction": "Yes",
    "Service Undeliverable": "Yes",
    "Account has been locked": "Yes",
    "Submitted bulk summary": "Yes",
  });

  const [eserviceEmails, setEserviceEmails] = useState({
    "Filing Accepted": "Yes",
    "Filing Rejected": "Yes",
    "Filing Submitted": "Yes",
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const saveChanges = () => {
    setIsEditing(false);
  };

  const handleRadioChange = (group, label, value) => {
    if (group === "efile") {
      setEfileEmails((prev) => ({
        ...prev,
        [label]: value,
      }));
    } else {
      setEserviceEmails((prev) => ({
        ...prev,
        [label]: value,
      }));
    }
  };

  const setAllPreferences = (group, value) => {
    if (group === "efile") {
      const updated = Object.keys(efileEmails).reduce((acc, key) => {
        acc[key] = value;
        return acc;
      }, {});
      setEfileEmails(updated);
    } else {
      const updated = Object.keys(eserviceEmails).reduce((acc, key) => {
        acc[key] = value;
        return acc;
      }, {});
      setEserviceEmails(updated);
    }
  };

  return (
    <RoundedBox className="mt-4 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-[#5261A0]">
          Email Preferences:
        </h2>
        {!isEditing ? (
          <Box className="cursor-pointer text-[#5261A0]" onClick={handleEdit}>
            <Edit />
          </Box>
        ) : (
          <Box
            className="cursor-pointer font-bold text-[#5261A0]"
            onClick={saveChanges}
          >
            Save Changes
          </Box>
        )}
      </div>

      {/* View/Edit Mode */}
      <Grid container spacing={2}>
        {/* EFILE EMAILS */}
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <h3 className="font-semibold text-[#5261A0] text-base mb-3">
                EFILE EMAILS
              </h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing && (
                <div className="flex mb-4">
                  <Box
                    className="cursor-pointer text-red-600 text-base mx-5 font-bold"
                    onClick={() => setAllPreferences("efile", "No")}
                  >
                    No to all
                  </Box>
                  <Box
                    className="cursor-pointer text-[#5261A0] text-base font-bold"
                    onClick={() => setAllPreferences("efile", "Yes")}
                  >
                    Yes to all
                  </Box>
                </div>
              )}
            </Grid>
          </Grid>

          {Object.keys(efileEmails).map((label) => (
            <Grid
              container
              key={label}
              spacing={1}
              alignItems="center"
              className="text-sm"
            >
              <Grid item xs={6} className="font-bold">
                {label}:
              </Grid>
              <Grid item xs={6} className="leading-[38px]">
                {isEditing ? (
                  <RadioGroup
                    row
                    value={efileEmails[label]}
                    onChange={(e) =>
                      handleRadioChange("efile", label, e.target.value)
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "0.875rem", // Make label smaller
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 20, // Reduce the size of the radio button
                      },
                    }}
                  >
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                ) : (
                  efileEmails[label]
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* ESERVICE EMAILS */}
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <h3 className="font-semibold text-[#5261A0] text-base mb-3">
                ESERVICE EMAILS
              </h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing && (
                <div className="flex mb-4">
                  <Box
                    className="cursor-pointer text-red-600 text-base mx-5 font-bold"
                    onClick={() => setAllPreferences("eservice", "No")}
                  >
                    No to all
                  </Box>
                  <Box
                    className="cursor-pointer text-[#5261A0] text-base font-bold"
                    onClick={() => setAllPreferences("eservice", "Yes")}
                  >
                    Yes to all
                  </Box>
                </div>
              )}
            </Grid>
          </Grid>

          {Object.keys(eserviceEmails).map((label) => (
            <Grid
              container
              key={label}
              spacing={1}
              alignItems="center"
              className="text-sm"
            >
              <Grid item xs={6} className="font-bold">
                {label}:
              </Grid>
              <Grid item xs={6} className="leading-[38px]">
                {isEditing ? (
                  <RadioGroup
                    row
                    value={eserviceEmails[label]}
                    onChange={(e) =>
                      handleRadioChange("eservice", label, e.target.value)
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "0.875rem", // Make label smaller
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 20, // Reduce the size of the radio button
                      },
                    }}
                  >
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                ) : (
                  eserviceEmails[label]
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </RoundedBox>
  );
};

export default EmailPreferences;
