import { ReactElement, useEffect, useState } from "react";
import DialogConfirm from "../../../components/dialogs/DialogConfirm";
import { ButtonA, FormButton, FormButtonLight } from "../../../components/forms/FormButton";
import RoundedBox from "../../../components/layout/RoundedBox";
import { DataGrid, GridColDef, GridRowHeightParams, GridRowHeightReturnValue } from "@mui/x-data-grid";
import { FormikProps, useFormik } from "formik";
import * as yup from 'yup';
import FormLabel from "../../../components/forms/FormLabel";
import FormSelect from "../../../components/forms/FormSelect";
import FormInput from "../../../components/forms/FormInput";
import FormTextarea from "../../../components/forms/FormTextarea";
import { Case, File, Filing, FilingComponent, FilingDocument, VALIDATION_EMAIL_MULTIPLE, humanBytes, objectToQuery, selectObjectByDot } from "../../../utils/utils";
import { WizardStep } from "../../../components/StepsWizard";
import { useSelector } from "react-redux";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { selectFilingCodes } from "../../../components/assets/CaseWizard";
import { useAuth } from "../../../hooks/auth";
import FormError from "../../../components/forms/FormError";
import useConfigs from "../../../hooks/useConfigs";

const getFilingComponentKey = (filingComponent: any): string => `${filingComponent.id}-${filingComponent.name}`;

const DialogMultiplier = ({ optionalService, onSubmit, onCancel, ...props }: { optionalService: any, onSubmit: Function, onCancel: Function }): ReactElement => {

  useEffect(() => {
    if(!optionalService) {
      return;
    }
    formik.setValues(optionalService);
  }, [optionalService])

  const formik = useFormik({
    initialValues: {
      multiplier: 1
    },
    validationSchema: () => {
      return yup.object().shape({
        multiplier: yup.number().integer().min(1)
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      onSubmit({...values, multiplier: 1}, values.multiplier)
    }
  })

  if(!optionalService) {
    return <></>;
  }

  return <DialogConfirm
    title={`${optionalService?.name}`}
    confirm={formik.submitForm}
    cancel={onCancel}
    open={optionalService}
    className="min-w-64"
  >
    Multiplier: <FormInput
      formik={formik}
      name="multiplier"
    />
  </DialogConfirm>
};
const OptionalServicesForm = ({ formik, name, optionalServices, ...props }: { formik: FormikProps<Filing>, name: string, optionalServices: any[] }): ReactElement => {
  
  const [selectedToAdd, setSeletedToAdd]:[any, Function] = useState();
  const [selectedToAddMultiplier, setSeletedToAddMultiplier]:[any, Function] = useState();
  const [selectedToRemove, setSeletedToRemove]:[any, Function] = useState();

  const add = (optionalService: any, multiplier = 1.0) => {
    const formikOptionalServices: any[] = [...selectObjectByDot(formik.values, name)]
    formikOptionalServices.push({
      multiplier,
      optional_service: optionalService
    })
    formik.setFieldValue(name, formikOptionalServices);
  }

  const addSelected = () => {
    if(!selectedToAdd) {
      return;
    }
    if(selectedToAdd.multiplier) {
      setSeletedToAddMultiplier({...selectedToAdd});
      setSeletedToAdd();
      return;
    }
    add(selectedToAdd);
    setSeletedToAdd()
  }

  const addSelectedMultiplier = (optionalService: any, multiplier = 1.0) => {
    add(optionalService, multiplier);
    setSeletedToAddMultiplier()
  }
  
  const removeSelected = () => {
    if(!selectedToRemove) {
      return;
    }
    const formikOptionalServices: any[] = [...selectObjectByDot(formik.values, name)].filter(optionalService => {
      return optionalService.optional_service.id != selectedToRemove.optional_service.id
    })
    formik.setFieldValue(name, formikOptionalServices);
    setSeletedToRemove()
  }

  if(!optionalServices || !formik.values || !name) {
    return <></>
  }
  return <>
    <select size={6} className="px-2 border rounded-md hover:border-gray-300 focus:outline-none focus:border-gray-300 h-32 bg-white overflow-y-auto block w-full">
      {
        optionalServices.filter((optionalService: any) => {
          return selectObjectByDot(formik.values, name).filter((formikOptionalService: any) => formikOptionalService.optional_service.id == optionalService.id).length === 0
        }).map((optionalService: any) => <option
          key={`not-selected-${optionalService.id}`}
          onClick={() => setSeletedToAdd(optionalService)}
        >
          {optionalService.name}
          {parseFloat(optionalService.fee) ? ` ($${optionalService.fee})` : ``}
        </option>)
      }
    </select>
    <div className="py-2 text-center">
      <div className="flex gap-4 w-64 m-auto">
        <ButtonA className="flex-1" onClick={addSelected}>Add &darr;</ButtonA>
        <ButtonA className="flex-1" onClick={removeSelected}>Remove &uarr;</ButtonA>
      </div>
    </div>
    <FormLabel>
      Selected optional services
    </FormLabel>
    <select size={6} className="px-2 border rounded-md hover:border-gray-300 focus:outline-none focus:border-gray-300 h-32 bg-white overflow-y-auto block w-full">
      {
        selectObjectByDot(formik.values, name).map((optionalService: any) => <option
          key={`selected-${optionalService.optional_service.id}`}
          onClick={() => setSeletedToRemove(optionalService)}
        >
          {optionalService.optional_service.name}
          {parseFloat(optionalService.optional_service.fee) ? ` ($${(optionalService.multiplier * optionalService.optional_service.fee).toFixed(2)})` : ``}
        </option>)
      }
    </select>
    {
        selectedToAddMultiplier && <DialogMultiplier
          optionalService={selectedToAddMultiplier} 
          onCancel={() => setSeletedToAddMultiplier()}
          onSubmit={addSelectedMultiplier}
        />
    }
  </>
}

const FilingDocumentUploader = ({ formik, name, fcase, documentTypeCodes, ...props }: { formik: any, name: string, fcase: Case, documentTypeCodes: any[] }): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });

  const [filingComponent, setFilingDocument]: [FilingComponent | undefined, Function] = useState()
  const [seletedFilingDocumentDelete, setSeletedFilingDocumentDelete]: [FilingDocument | undefined, Function] = useState()

  const {isVisible, isRequired} = useConfigs();

  useEffect(() => {
    if (!formik.values || !name) {
      return;
    }
    setFilingDocument(selectObjectByDot(formik.values, name));
  }, [formik.values, name])

  const handleFileUpload = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }
    const file = await uploadFile(selectedFile);
    if (!file) {
      return;
    }
    const components: any = { ...formik.values.components };
    components[getFilingComponentKey(filingComponent)].files.push(file);
    formik.setFieldValue('components', components)
  }

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filing_case_id', `${fcase?.id}`);
    formData.append('filing_component_id', `${filingComponent?.id}`);

    try {
      const { data } = await axios.post(`filing-documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }

  const doDelete = async () => {
    if (!filingComponent) {
      return;
    }
    const files = filingComponent.files.filter((filingDocument: FilingDocument) => filingDocument != seletedFilingDocumentDelete)
    formik.setFieldValue(`components.${getFilingComponentKey(filingComponent)}.files`, files)
    setSeletedFilingDocumentDelete(undefined);
  }

  const getFile = async (filingDocument: FilingDocument) => {
    const { data } = await axios.get(`filing-documents/${filingDocument.id}`);

    const link = document.createElement('a');
    link.href = data.tmp_url;
    link.download = filingDocument.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (!filingComponent) {
    return <></>;
  }

  return <div className="flex flex-col gap-4">
    {
      filingComponent.files.map((filingDocument: FilingDocument, index: number) => <div key={`file-${index}`}>
        <div className="flex gap-4">
          <div className="flex-1">
            {
              index == 0 && <FormLabel mandatory={filingComponent.required}>{filingComponent.name}</FormLabel>
            }
          </div>
          <div className="flex-1">
            <FormLabel>
              Security
            </FormLabel>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1">
            <div
              className="rounded-md bg-button-light-blue text-center p-4 hover:bg-gray-100 cursor-pointer shadow-left-and-bottom-small flex flex-col justify-center"
              style={{ minHeight: '63px' }}
            >
              <div>
                <a onClick={() => getFile(filingDocument)} className="hover:underline hover:text-base-blue">
                  {filingDocument.name} ({humanBytes(filingDocument.size)})
                </a>
                <DeleteIcon onClick={() => setSeletedFilingDocumentDelete(filingDocument)} />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <FormLabel mandatory={isRequired('DocumentType')}>
              Documnet type
            </FormLabel>
            <FormSelect
              formik={formik}
              options={documentTypeCodes}
              optionValue="id"
              optionLabel="name"
              name={`${name}.files.${index}.mfr_document_type_id`}
            />
          </div>
        </div>
      </div>)
    }
    {
      (filingComponent.files.length == 0 || !!filingComponent.allow_multiple) && <div>
        {
          filingComponent.files.length == 0 && <FormLabel mandatory={filingComponent.required}>{filingComponent.name}</FormLabel>
        }
        <label>
          <div
            className="rounded-md bg-white text-center p-4 hover:bg-gray-100 cursor-pointer shadow-left-and-bottom-small flex flex-col justify-center"
            style={{ minHeight: '63px' }}
          >
            <div>
              <FileUploadIcon /> Browse Computer
            </div>
            <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
          </div>
        </label>
      </div>
    }
    <FormError formik={formik} name={`${name}.files`} />
    <DialogConfirm
      title='Delete document'
      confirm={doDelete}
      cancel={() => { setSeletedFilingDocumentDelete(undefined) }}
      open={seletedFilingDocumentDelete}
      className="min-w-64"
    >
      Are you sure to delete the document <b>{seletedFilingDocumentDelete?.name}</b>?
    </DialogConfirm>
  </div>
}
// --------------------------------------------

const FilingForm = ({ fcase, filing = false, onSubmit, ...props }: { fcase: Case, filing: Filing | boolean, onSubmit?: Function }): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });

  const [documentTypeCodes, setDocumentTypeCodes] = useState([]);
  const [optionalServices, setOptionalServices] = useState([]);
  const [componentCodes, setComponentCodes] = useState([]);

  const filingCodes = useSelector(selectFilingCodes);

  const {isVisible, isRequired} = useConfigs();

  useEffect(() => {
    if (!filing) {
      return;
    }
    formik.setValues(filing && typeof filing === 'boolean' ? initialValues : filing);
    if (typeof filing !== 'boolean' && filing.filing_code_id) {
      onChangeFilingCode({ id: filing.filing_code_id }, false)
    }
  }, [filing]);

  const initialValues: Filing = {
    filing_code_id: undefined,
    description: '',
    client_number: '',
    courtesy_copies: '',
    instructions: '',
    preliminary_copies: '',
    components: {},
    filing_file_optional_services: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      const components: any = {}
      for (let i in formik.values.components) {
        const filingComponent: FilingComponent = formik.values.components[i]
        const rules: any = {};
        const is_required = isRequired('DocumentType');
        rules.files = yup.array()
        if(is_required) {
          rules.files.of(
            yup.object().shape({
              mfr_document_type_id: yup.string().required("Please select a document type"),
            })
          );
        }
        if (!!filingComponent.required) {
          rules.files.min(1, "Please upload a document");
        }
        if (!filingComponent.allow_multiple) {
          rules.files.max(1, "Please upload only one document");
        }
        components[i] = yup.object().shape(rules)
      }
      const obj = {
        filing_code_id: yup.string().required('Please select a type'),
        description: yup.string().required('Please enter a description'),
        client_number: yup.string().required('Please select a type'),
        courtesy_copies: yup.string().matches(VALIDATION_EMAIL_MULTIPLE, 'Please enter multiple emails separated by commas'),
        preliminary_copies: yup.string().matches(VALIDATION_EMAIL_MULTIPLE, 'Please enter multiple emails separated by commas'),
        components: yup.object().shape(components)
      };
      return yup.object().shape(obj);
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!values.id) {
          await axios.post(`/filing-cases/${fcase.id}/filing-files`, values)
        } else {
          await axios.put(`/filing-cases/${fcase.id}/filing-files/${values.id}`, values)
        }
        onSubmit && onSubmit(values)
      } catch (e) {
        console.error(e)
      }
    }
  });

  const onChangeFilingCode = (filingCode: any, changeDesc = true) => {
    if(filingCode && changeDesc) {
      formik.setFieldValue('description', filingCode.name);
    }
    fetchDocumentTypeCodes(filingCode)
    fetchOptionalServices(filingCode)
    fetchComponentCodes(filingCode)
  };

  const fetchDocumentTypeCodes = async (filingCode: any) => {
    let data = [];
    if (filingCode) {
      const params = objectToQuery({ location_id: fcase.location_id, filing_code_id: filingCode.id })
      data = (await axios.get(`/document-types?${params}`)).data
    }
    setDocumentTypeCodes(data)
  };

  const fetchOptionalServices = async (filingCode: any) => {
    let data = [];
    if (filingCode) {
      const params = objectToQuery({ location_id: fcase.location_id, filing_code_id: filingCode.id })
      data = (await axios.get(`/optional-services?${params}`)).data
    }
    setOptionalServices(data)
  };

  const fetchComponentCodes = async (filingCode: any) => {
    let data = [];
    if (filingCode) {
      const params = objectToQuery({ location_id: fcase.location_id, filing_code_id: filingCode.id })
      data = (await axios.get(`/filing-components?${params}`)).data
    }
    const components: any = {};
    for (let filingComponent of data) {
      let filingFileFilingComponent: any = {}
      if (typeof filing !== 'boolean') {
        filingFileFilingComponent = filing.components[getFilingComponentKey(filingComponent)]
      }
      components[getFilingComponentKey(filingComponent)] = {
        ...filingComponent,
        files: [],
        ...filingFileFilingComponent
      };
    }
    formik.setFieldValue('components', components);
  };

  const filingComponentsToArray = (components: any) => {
    const arr = [];
    for (let i in components) {
      arr.push(components[i]);
    }
    return arr.sort((fc1: any, fc2: any) => fc1.display_order - fc2.display_order)
  }

  // --------------------------------------------

  return <>
    <label className="block mb-4">
      Enter the details about your filings.
    </label>
    <form onSubmit={formik.handleSubmit}>
      <div className="gap-4">
        <div className="flex gap-4 mb-6">
          <div className="flex-1">
            <FormLabel mandatory>
              Filing Type
            </FormLabel>
            <FormSelect
              formik={formik}
              name="filing_code_id"
              options={filingCodes}
              optionValue="id"
              optionLabel="name"
              empty="Select a type"
              onChange={onChangeFilingCode}
            />
          </div>
          <div className="flex-1">
            <FormLabel mandatory>
              Filing Description
            </FormLabel>
            <FormInput
              formik={formik}
              name="description"
            />
          </div>
        </div>
        <div className="flex gap-4 mb-6">
          <div className="flex-1">
            <FormLabel mandatory>
              Client and/or Matter Number
            </FormLabel>
            <FormInput
              formik={formik}
              name="client_number"
              placeholder='Enter client and/or matter number'
            />
          </div>
          <div className="flex-1">
            <FormLabel>
              Courtesy Copies (Optional)
            </FormLabel>
            <FormInput
              formik={formik}
              name="courtesy_copies"
              placeholder="You can add multiple emails separated by commas"
            />
          </div>
        </div>
        <div className="flex gap-4 mb-6">
          {
            isVisible('FilingFilingComments') && <div className="flex-1">
              <FormLabel mandatory={isRequired('FilingFilingComments')}>
                Enter message or instructions for the court clerk (Optional)
              </FormLabel>
              <FormTextarea
                formik={formik}
                name="instructions"
                placeholder='Enter message'
              />
            </div>
          }
          <div className="flex-1">
            <FormLabel>
              Preliminary Copies (Optional)
            </FormLabel>
            <FormInput
              formik={formik}
              name="preliminary_copies"
              placeholder="You can add multiple emails separated by commas"
            />
          </div>
        </div>
        <div className="flex gap-4 mb-6">
          <div className="flex-1">
            {
              optionalServices && optionalServices.length > 0 && <>
                <FormLabel>
                  Optional services
                </FormLabel>
                <OptionalServicesForm
                  formik={formik}
                  name="filing_file_optional_services"
                  optionalServices={optionalServices}
                />
              </>
            }
          </div>
          <div className="flex-1">
            
          </div>
        </div>
      </div>
      {
        formik.values.components && Object.keys(formik.values.components).length > 0 && <RoundedBox className="bg-background-light-blue mb-4 flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            {
              filingComponentsToArray(formik.values.components).map((filingComponent, index) => <div key={`filing-document-uploader-${index}`}>
                <FilingDocumentUploader formik={formik} name={`components.${getFilingComponentKey(filingComponent)}`} fcase={fcase} documentTypeCodes={documentTypeCodes} />
              </div>
              )
            }
          </div>
        </RoundedBox>
      }
      <div className="flex gap-4">
        <div className="flex-1">
        </div>
        {
          typeof filing === 'boolean' && <>
            <div className="flex flex-initial w-60">
              <FormButtonLight
                className="flex-1"
                type="submit"
                name="createAnotherFiling"
                value={false}
                onClick={formik.handleChange}
              >
                Save and Next
              </FormButtonLight>
            </div>
            <div className="flex flex-initial w-60">
              <FormButton
                className="flex-1"
                type="submit"
                name="createAnotherFiling"
                value={true}
                onClick={formik.handleChange}
              >
                Save and Add another Lead Document
              </FormButton>
            </div>
          </>
        }
        {
          typeof filing != 'boolean' && <>
            <div className="flex flex-initial w-60">
              <FormButtonLight
                className="flex-1"
                type="submit"
                name="createAnotherFiling"
                value={false}
                onClick={formik.handleChange}
              >
                Update Filing
              </FormButtonLight>
            </div>
          </>
        }
      </div>
    </form>
  </>
};

// --------------------------------------------

const FilingStep = ({ step, fcase, saveFcase, fetchFcase, setShowPrevButton, setShowNextButton, fetchFilingCodes, ...props }: { step: WizardStep, fcase: Case, saveFcase: Function, fetchFcase: Function, setShowPrevButton: Function, setShowNextButton: Function, fetchFilingCodes: Function }): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });

  const [selectedFiling, setSelectedFiling]: [Filing | boolean, Function] = useState(false);
  const [selectedFilingDelete, setSeletedFilingDelete]: [Filing | undefined, Function] = useState();

  const filingCodes = useSelector(selectFilingCodes)

  useEffect(() => {
    if (!filingCodes && fcase && fcase.case_type_id) {
      fetchFilingCodes({ id: fcase?.case_type_id }, { initial: fcase?.initial })
    }
  }, [fcase]);

  useEffect(() => {
    if (!fcase) {
      return;
    }
    step.props.filing_files = fcase.filing_files
  }, [fcase?.filing_files]);

  useEffect(() => {
    if(!step) {
      return;
    }
    if (step.current) {
      if (fcase?.filing_files?.length == 0) {
        enableShowFilingForm(true)
      }
    } else {
      disableShowFilingForm()
    }
  }, [step.current]);

  // --------------------------------------------

  const getRowHeight = (params: GridRowHeightParams): GridRowHeightReturnValue => {
    if (!params.model?.components) {
      return 40;
    }
    let height = 0;
    for (let i in params.model.components) {
      let component = params.model.components[i];
      height += component.files?.length + 1;
    }
    height = (height + 1) * 18;
    return height > 48 ? height : 48;
  }

  const getFilingCode = (value: any, row: any) => {
    for (const filingCode of filingCodes) {
      if (filingCode.id == value) {
        return filingCode.name
      }
    }
    return ''
  }

  const getFile = async (filingDocument: any) => {
    const { data } = await axios.get(`filing-documents/${filingDocument.id}`);

    const link = document.createElement('a');
    link.href = data.tmp_url;
    link.download = filingDocument.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const getUploadedDocuments = (params: any) => {
    if (!Object.keys(params.value).length) {
      return <></>
    }
    const components = [];
    let height = 0;
    for (let i in params.value) {
      let component = params.value[i];
      height += component.files?.length + 1;
      components.push(component);
    }
    height = height * 8;

    return <div className="relative" style={{ bottom: `${height}px` }}>
      {
        components.map((component: FilingComponent, index: number) => <div key={`component-${index}`}>
          <div style={{ height: '15px', 'fontSize': '10px' }} className="text-gray-500 font-bold">{component.name}</div>
          {
            component.files.map((file: File, index: number) => {
              return <a
                href={file.url}
                className="py-1 h-6 text-sky-500 hover:text-sky-600 block pl-4 cursor-pointer"
                key={`file-${index}`}
                onClick={() => getFile(file)}
              >
                {file.name} ({humanBytes(file.size)})
              </a>
            })
          }
        </div>)
      }
    </div>
  }

  const actions = (params: any) => <div className="text-right">
    <DeleteIcon
      onClick={() => setSeletedFilingDelete(params.row)}
      className="cursor-pointer"
    />
    
    <EditIcon
      onClick={() => editFiling(params.row)}
      className="cursor-pointer mr-1"
    />
  </div>

  const columns: GridColDef[] = [
    { field: 'filing_code_id', headerName: 'Filing Type', width: 300, valueGetter: getFilingCode },
    { field: 'description', headerName: 'Description', flex: 300 },
    { field: 'client_number', headerName: 'Client number', flex: 150 },
    // { field: 'title', headerName: 'Title', flex: 200 },
    { field: 'components', headerName: 'Uploaded Documents', flex: 300, renderCell: getUploadedDocuments },
    { field: 'action', headerName: 'Action', flex: 150, renderCell: actions, align: 'right', headerAlign: 'right' },
  ];

  // --------------------------------------

  const editFiling = (filing: Filing) => {
    enableShowFilingForm({ ...filing });
  }

  const doDelete = async () => {
    await axios.delete(`filing-cases/${fcase.id}/filing-files/${selectedFilingDelete?.id}`);

    fetchFcase()
    setSeletedFilingDelete(undefined)
  }

  const enableShowFilingForm = (filing: Filing | boolean = true) => {
    setShowNextButton(false)
    setSelectedFiling(filing)
    step.props.showingNewFilingForm = true
    step.props.disableShowFilingForm = disableShowFilingForm
  }

  const disableShowFilingForm = () => {
    setShowNextButton(true)
    setSelectedFiling(null)
    delete step.props.showingNewFilingForm
    delete step.props.disableShowFilingForm
  }

  const onFilingFormSubmit = async (values: any) => {
    disableShowFilingForm();
    await fetchFcase();
    if (values.createAnotherFiling == 'true') {
      setTimeout(() => {
        enableShowFilingForm()
      }, 10)
    }
  }

  // --------------------------------------------

  if (!fcase) {
    return <></>
  }

  return <>
    {
      fcase.filing_files.length > 0 && !selectedFiling && <RoundedBox className="bg-white mb-4">
        <div className="mb-4">
          Case Filings
        </div>
        {
          step.current && <DataGrid
            getRowId={(row) => row.id}
            autoHeight // not working
            rows={fcase.filing_files}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            className="grid-mfr mb-4"
            rowSelection={false}
            getRowHeight={getRowHeight}
          />
        }
        <div className="text-center">
          <FormButtonLight onClick={() => enableShowFilingForm(true)} className="m-auto w-1/4 rounded-full">
            Add New Filing
          </FormButtonLight>
        </div>
      </RoundedBox>
    }
    {
      selectedFiling && <FilingForm filing={selectedFiling} onSubmit={onFilingFormSubmit} fcase={fcase} />
    }
    <DialogConfirm
      title='Delete filing'
      confirm={doDelete}
      cancel={() => { setSeletedFilingDelete(undefined) }}
      open={selectedFilingDelete}
      className="min-w-64"
    >
      Are you sure to delete <b>{selectedFilingDelete?.description}</b>?
    </DialogConfirm>
  </>
};

export default FilingStep;