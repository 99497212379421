import { useState, ReactElement } from "react";
import { Grid, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import RoundedBox from "../../../components/layout/RoundedBox";
import SmallTextField from "../../../components/Inputs/SmallInput";

// Mock user object outside the component
const mockUser = {
  firmName: "Texas LLC",
  firstName: "John",
  middleName: "",
  lastName: "Cook",
  email: "johncook@texasllc.com",
  phoneNumber: "+1 344 6778",
  streetAddress: "Tranquil Lane 1889",
  addressLine2: "Apt. 412",
  city: "Austin",
  state: "TX",
  zipCode: "78613",
};

const AccountInformation = (): ReactElement => {
  // State to manage user data and edit mode
  const [user, setUser] = useState(mockUser);
  const [isEditing, setIsEditing] = useState(false);

  // Handle input change for user fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const saveChanges = () => {
    setIsEditing(false);
  };

  return (
    <RoundedBox className="bg-white mb-4">
      <Box className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-[#5261A0]">
          Account Information:
        </h2>
        {!isEditing ? (
          <Box className="cursor-pointer text-[#5261A0]" onClick={handleEdit}>
            <Edit />
          </Box>
        ) : (
          <Box
            className="cursor-pointer font-bold text-[#5261A0]"
            onClick={saveChanges}
          >
            Save Changes
          </Box>
        )}
      </Box>

      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Firm Name:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="firmName"
                  value={user.firmName}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.firmName}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              First Name:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="firstName"
                  value={user.firstName}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.firstName}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Middle Name:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="middleName"
                  value={user.middleName}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.middleName}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Last Name:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="lastName"
                  value={user.lastName}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.lastName}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              E-mail:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.email}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Phone Number:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="phoneNumber"
                  value={user.phoneNumber}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.phoneNumber}</p>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Street Address:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="streetAddress"
                  value={user.streetAddress}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.streetAddress}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Address Line 2:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="addressLine2"
                  value={user.addressLine2}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.addressLine2}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              City:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="city"
                  value={user.city}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.city}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              State:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="state"
                  value={user.state}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.state}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="font-bold text-sm">
              Zip Code:
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <SmallTextField
                  name="zipCode"
                  value={user.zipCode}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-sm">{user.zipCode}</p>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RoundedBox>
  );
};

export default AccountInformation;
