import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import { Case } from "../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import useFilingCases from "../../hooks/useFilingCases";
import FormLabel from "../../components/forms/FormLabel";
import { FormButton } from "../../components/forms/FormButton";
import BackArrow from "../../components/BackArrow";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import { CaseDetails } from "./FileCaseSteps/SummaryStep";

const CaseDetailsScreen = ({ open, onClose, ...props }: any): ReactElement => {

  const navigate = useNavigate();
  const { id, location_id } = useParams();
  const [fcase, setFcase]: [Case | undefined, Function] = useState();
  const [isMfr, setIsMfr] = useState(true);
  const { fetchFilingCaseById, fetchExistingFilingCaseById } = useFilingCases();
  const { addMessageError } = useMessageBoard();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (/[a-f0-9]+-[a-f0-9-]+/.test(id) && location_id) {
      setIsMfr(false)
      fetchExistingFcase(id, parseInt(location_id))
    } else if (/[0-9]+/.test(id)) {
      setIsMfr(true)
      fetchMfrFcase(parseInt(id))
    }
  }, [id]);

  const fetchMfrFcase = async (id: number) => {
    try {
      const fcase = await fetchFilingCaseById(id, true)
      setFcase(fcase);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  const fetchExistingFcase = async (id: string, location_id: number) => {
    try {
      const fcase = await fetchExistingFilingCaseById(id, location_id);
      console.log(fcase);
      setFcase(fcase);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  const fileIntoThisFcase = async () => {
    try {
      const fcase = await fetchExistingFilingCaseById(`${id}`, parseInt(`${location_id}`), true);
      navigate(`/file-into-existing-case/${fcase?.id}`);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  return <PrivateLayout>
    <BackArrow />
    {
      fcase && <RoundedBox className="bg-white mt-4">
        <div className="flex border-b-gray-300 border-b-1 pb-2">
          <div className="flex-1 flex flex-col justify-center">
            <FormLabel>
              Filing Summary
            </FormLabel>
          </div>
          <div className="flex-initial">
            {
              !isMfr && <FormButton className="px-20" onClick={fileIntoThisFcase}>
                File into this case
              </FormButton>
            }
          </div>
        </div>
        <CaseDetails
          fcase={fcase}
          showFees={false}
          editable={false}
        />
      </RoundedBox>
    }
  </PrivateLayout>
}

export default CaseDetailsScreen;
