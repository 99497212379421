import { Delete, Edit, Visibility } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useFilings, { FilingFilters } from '../../hooks/useFilings';
import CustomTable from '../table/CustomTable';
import FilterFilingsPanel from './filters/FilterFilingsPanel';
import FilterFilingsSearchBar from './filters/FilterFilingsSearchBar';

const MyFilingsTab = () => {
  const { filings, loading, fetchFilings } = useFilings();
  const navigate = useNavigate()
  const [filters, setFilters] = useState<FilingFilters>({
    search: '',
    statuses: [],
    envelope: '',
    caseNumber: '',
    clientMatterNumber: '',
    dateFrom: null,
    dateTo: null,
  });

  // Fetch filings whenever filters change
  useEffect(() => {
    fetchFilings(filters);
  }, [filters, fetchFilings]);

  // Handlers for search and filters
  const handleSearchChange = useCallback((searchTerm: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchTerm,
    }));
  }, []);

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  }, []);

  // Define columns for the table with color mappings for the filingStatus field
  const columns = [
    { field: 'envelope', headerName: 'Envelope N' },
    { field: 'caseNumber', headerName: 'Case N' },
    { field: 'clientMatterNumber', headerName: 'Client Matter N' },
    {
      field: 'filingStatus',
      headerName: 'Filing Status',
      colorMapping: {
        Failed: 'red',
        Submitting: '#0096ff',
        Submitted: '#ffcc00',
        Accepted: 'green',
        Rejected: 'red',
      },
    },
    { field: 'lastUpdate', headerName: 'Last Update' },
    { field: 'description', headerName: 'Description' },
  ];

  const handleViewFiling = (id: number) => {
    navigate(`${id}`);
  };

  const handleEditFiling = (id: number) => {
    console.log('Editing filing with ID: ', id);
  };

  const handleDeleteFiling = (id: number) => {
    console.log('Deleting filing with ID: ', id);
  };

  const actions = (status: string) => {
    const baseActions = [
      {
        label: 'View',
        onClick: handleViewFiling,
        icon: <Visibility className="text-[#5261A0]" />,
      },
    ];

    if (status === 'Failed') {
      baseActions.push({
        label: 'Edit',
        onClick: handleEditFiling,
        icon: <Edit className="text-[#5261A0]" />,
      });
    }

    if (status === 'Submitting' || status === 'Drafts') {
      baseActions.push({
        label: 'Delete',
        onClick: handleDeleteFiling,
        icon: <Delete className="text-[#5261A0]" />,
      });
    }

    return baseActions;
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <FilterFilingsSearchBar onSearchChange={handleSearchChange} />
        <FilterFilingsPanel onFilterChange={handleFilterChange} />
      </div>
      <CustomTable
        rows={filings.map((filing) => ({
          ...filing,
          actions: actions(filing.filingStatus),
        }))}
        columns={columns}
        loading={loading}
      />
      <Box className="flex flex-col w-full">
        <Link
          to="/file-case"
          className="bg-[#E7ECF8] w-[405px] text-[#5261A0] rounded-full text-center mx-auto text-base py-2 px-5 hover:bg-[#D0D8EE]"
        >
          Submit New Filing
        </Link>
        <span className="m-auto text-base text-[#5261A0] mt-4">
          No time to File?
          <Link to="/contact-us" className="text-[#5261A0] hover:underline ml-1">
            We can do it for you.
          </Link>
        </span>
      </Box>
    </>
  );
};

export default MyFilingsTab;
