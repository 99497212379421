import { useState, ReactElement } from "react";
import { Grid, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import RoundedBox from "../../../components/layout/RoundedBox";
import SmallTextField from "../../../components/Inputs/SmallInput";

const PasswordInformation = (): ReactElement => {
  const [isEditing, setIsEditing] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: "************",
    securityQuestion: "What was your elementary school’s name?",
    securityAnswer: "************",
  });

  // Handle input change for password fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const saveChanges = () => {
    setIsEditing(false);
  };

  return (
    <RoundedBox className="bg-white h-full">
      <Box className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-[#5261A0]">
          Password Information:
        </h2>
        {!isEditing ? (
          <Box className="cursor-pointer text-[#5261A0]" onClick={handleEdit}>
            <Edit />
          </Box>
        ) : (
          <Box
            className="cursor-pointer font-bold text-[#5261A0]"
            onClick={saveChanges}
          >
            Save Changes
          </Box>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className="font-bold text-sm">
          Current Password:
        </Grid>
        <Grid item xs={12} sm={6}>
          {isEditing ? (
            <SmallTextField
              name="currentPassword"
              type="password"
              value={passwordData.currentPassword}
              onChange={handleInputChange}
            />
          ) : (
            <p className="text-sm">{passwordData.currentPassword}</p>
          )}
        </Grid>

        <Grid item xs={12} sm={4} className="font-bold text-sm">
          Security Question:
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className="text-sm">{passwordData.securityQuestion}</p>
        </Grid>

        <Grid item xs={12} sm={4} className="font-bold text-sm">
          Security Answer:
        </Grid>
        <Grid item xs={12} sm={6}>
          {isEditing ? (
            <SmallTextField
              name="securityAnswer"
              type="password"
              value={passwordData.securityAnswer}
              onChange={handleInputChange}
            />
          ) : (
            <p className="text-sm">{passwordData.securityAnswer}</p>
          )}
        </Grid>
      </Grid>
    </RoundedBox>
  );
};

export default PasswordInformation;
