import { ReactElement } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import RoundedBox from "../../../components/layout/RoundedBox";
import { useAuth } from "../../../hooks/auth";

const ProfileCard = (): ReactElement => {
  const { logout } = useAuth();
  const handleEdit = () => {
    // Handle edit logic here
    console.log("Edit profile image");
  };

  const handleLogout = () => {
    try {
      logout();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <RoundedBox className="flex flex-col bg-white relative">
      <Box className="relative flex justify-center items-center">
        <img
          src="https://via.placeholder.com/200"
          alt="Profile"
          className="rounded-xl mb-4"
        />
        <IconButton
          onClick={handleEdit}
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
            color: "#5261A0",
          }}
        >
          <Edit />
        </IconButton>
      </Box>

      <h3 className="font-semibold text-black text-2xl">John Cook</h3>
      <p>Administrator</p>

      <Button
        onClick={handleLogout}
        variant="contained"
        sx={{
          px: 3,
          py: 1.5,
          backgroundColor: "#5261A0",
          color: "white",
          borderRadius: "8px",
          marginTop: "2rem",
          marginBottom: "0.5rem",
          fontSize: "0.875rem",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "#3f4d88",
          },
        }}
      >
        Log Out
      </Button>
    </RoundedBox>
  );
};

export default ProfileCard;
