import { ReactElement } from "react";
import { Grid, Box } from "@mui/material";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import EmailPreferences from "./_components/EmailPreferences";
import AccountInformation from "./_components/AccountInfo";
import PasswordInformation from "./_components/PasswordInfo";
import Notifications from "./_components/Notifications";
import ProfileCard from "./_components/ProfileCard";

const AccountPage = (): ReactElement => {
  return (
    <PrivateLayout>
      <Grid container spacing={2} className="p-2">
        {/* Left Column */}
        <Grid item xs={12} md={10}>
          <AccountInformation />

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <PasswordInformation />
            </Grid>

            <Grid item xs={12} md={4}>
              <Notifications />
            </Grid>
          </Grid>

          <Box mt={2}>
            <EmailPreferences />
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Profile Card that spans two rows */}
          <Box sx={{ flexGrow: 1, minHeight: "400px" }}>
            <ProfileCard />
          </Box>

          {/* Delete Account Button */}
          <Box mt={6} textAlign="center" sx={{ mt: "auto" }}>
            <RoundedBox>
              <button className="text-red-500 font-bold">Delete Account</button>
            </RoundedBox>
          </Box>
        </Grid>
      </Grid>
    </PrivateLayout>
  );
};

export default AccountPage;
